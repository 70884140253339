// MyModal.js
import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import SpinWheel from '../Reward/rewardspin'
// import giftimg from '../Assets/Projects/ads-reward.gif'
import imgsrcreward from "../../Assets/Projects/sad-emoji.png"
import { useToasts } from 'react-toast-notifications';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";



function Notenoughcoin({ show, onHide }) {
  const { addToast } = useToasts();
  const [setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();


  const dailyreward = () => {

    if (true) {
      let adRewardFlag = true;
      let adWatch = false;
      
      window.adBreak({
        type: "reward",
        name: "dailyreward",
        beforeReward: (showAdFn) => {
          adRewardFlag = false;
          showAdFn();
        },
        adDismissed: () => {console.log("Ad Dismissed before time"); addToast('You exceeded the ad limit ', { appearance: 'warning', autoDismiss: true });},
        adViewed: () => {
          console.log("Ad Dismissed after time");
          adRewardFlag = true;
          adWatch = true;

          console.log("after adviewed close popup check");
          onHide()

        },
        adBreakDone: () => {
          if (adRewardFlag && adWatch) {
            let oldcoins = localStorage.getItem('coins')
            localStorage.setItem("coins", Number(oldcoins)+100);
            addToast('100 Coins Rewarded!', { appearance: 'success', autoDismiss: true });
            navigate("/playerzone/"+ id)

            console.log('after breakdone for close popup');

            onHide()
            
          } else {
            addToast('RewardAds Not Available', { appearance: 'error', autoDismiss: true });
            console.log("RewardAds not available, Try Again");
            onHide()

          }
        },
      });
    } else {
      alert("Daily rewarad Limit reached")
    }
  };


  return (
    <Modal
      // {...props}
      show={show} // Use show prop to control visibility
      onHide={onHide} // Call onHide function to close modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{backdropFilter:"blur(30px)"}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Oops!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row row-cols-1'>
            <div className='col'><img src={imgsrcreward} className="reward-img" alt="avatar"/></div>
            <div className='col'><h3>Coins Not Enough To Play Game.</h3>
                <p>Whatch ad and get 100 coins</p>
            </div>
            <div className='col'><Button onClick={dailyreward}>Watch Ads</Button></div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default Notenoughcoin;
