// MyModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
// import SpinWheel from '../Reward/rewardspin'
// import giftimg from '../Assets/Projects/ads-reward.gif'
import imgsrcreward from "../../Assets/Projects/thumbsupEmoji.png"


function Earnedcoins(props) {
    let totalcoins= localStorage.getItem('coins')
    let wantcoins = Number(totalcoins) - 2000
    return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{backdropFilter:"blur(30px)"}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
            Wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row row-cols-1'>
            <div className='col'><img src={imgsrcreward} width={250} alt="avatar"/></div>
            <div className='col'><h3>You have {totalcoins} Coins</h3>
                <p>Coming soon Excited Rewards keep playing</p>
                <p>Use coins for play more games</p>
            </div>
            {/* <div className='col'><Button>Claim</Button></div> */}
        </div>

      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default Earnedcoins;
