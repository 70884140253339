// MyModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
// import SpinWheel from '../Reward/rewardspin'
// import giftimg from '../Assets/Projects/ads-reward.gif'
import imgsrcreward from "../../Assets/Projects/ads-reward.gif"
import Rewarded from "../popups/coinsadded";




function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Ads Reward
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row row-cols-1'>
            <div className='col'><img src={imgsrcreward} className="reward-img" alt="avatar"/></div>
            <div className='col'><h3>Watch Ad and Claim 100 Coins</h3></div>
            <div className='col'><Rewarded/></div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
