import React from 'react';
import RewardSpin from './spin'; // Adjust the path accordingly

const rewardads = ({ show, onHide }) => {
  return (
    <div>
      {show && <RewardSpin show={show} onHide={onHide} />}
      {/* Rest of notenoughcoins */}
    </div>
  );
};

export default rewardads;
