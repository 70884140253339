import React from "react";
import { Container } from "react-bootstrap";

function About() {
  return (
    <Container fluid className="about-section">
      {/* <Particle /> */}
      <Container>
      <div className="row pb-5 mb-5 pt-4">
          <h1>About</h1>
        <div className="col mt-5">
          <p>
            Welcome to Milody Games, an exciting new world for boys and girls
            game lovers.
          </p>
          <p>
            Milody Games has always tried to deliver freshness in the segment of
            baby games and special games designed for you.
          </p>
          <p>
            The lone aim of the website is to provide optimally educational
            games based on babies and feminism that give an enjoyable as well as
            a fruitful experience to our gamers.
          </p>
          <p>
            These games at Milody Games range for all age groups and have a vast
            spread of different games matching up to every gamers’ tastes.
          </p>
          <p>
            There is a lengthy list of games differed by categories such as baby
            care, babysitting , baby bathing and many other games for girls like
            spa, hair, fashion, cooking, dress up, cleaning, shopping,
            restaurant , makeup, makeover , nail, animal, decorating and
            coloring games.
          </p>
          <p>
            We further plan to expand this list by adding more exciting games
            for kids, new or soon to be moms and also for teens.
          </p>
          <p>
            So, now you can learn a lot and have fun while gaming at Milody
            Games.
          </p>
          <p>
            Parents can be extremely carefree as all our contents are vigilantly
            handpicked by our team of experts and thus are completely safe for
            kids.
          </p>
          <p>
            They are educational games that teach a lot to moms and children
            with absolutely no spending at all.
          </p>
          <p>
            Our ultimate aim is to provide fun time online for moms and their
            kids with varieties of different games of multiple levels.
          </p>
        </div>
      </div>        
      </Container>
    </Container>

    );
  }

export default About;