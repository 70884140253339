import React, { useRef, useState } from "react";
import Notenoughcoin from "../popups/notenough-open";
import { useNavigate } from "react-router-dom";

function CardContainer() {
  const scrollListRef = useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  setTimeout(scrollRight, 3000);
  setTimeout(scrollLeft, 5000);

  // Start for not enought coin
  const [showModal, setShowModal] = useState(false);
  let coins = Number(localStorage.getItem("coins"));

  const openModalnocoin = (geturl) => {
    // if (coins && coins < 50) {
      // InterAd Show
      window.adBreak({
        type: "start",
        name: "game_started",
        adBreakDone: (placementInfo) => {
          console.log(placementInfo.breakStatus);
          // let oldcoins = localStorage.getItem('coins')
            //  let deductcoins= Number(oldcoins)-50
            //  localStorage.setItem('coins', deductcoins)
          navigate("/gamedetails/" + geturl.data);
        },
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // End of not enough coin

  return (
    <div className="card-container mb-3">
      <Notenoughcoin show={showModal} onHide={closeModal} />
      <div className="row py-3 px-1">
        <div className="col">
          <h4>
            Popular <i className="fa-solid fa-fire"></i>
          </h4>
        </div>
        <div className="col d-flex justify-content-end">
          <div>
            <button className="scroll-btn" onClick={scrollLeft}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </div>
          <div>
            <button className="scroll-btn" onClick={scrollRight}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-flex overflow-x-scroll text-center"
        ref={scrollListRef}
        id="populargame"
      >
         <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 9 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/basketball-challenge/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Basket Ball</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 5 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/drawmaster/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Draw Master</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 8 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/house-paint/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>House Paint</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 1 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/slider/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Slider</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 2 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/puzzle-color/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Puzzle Color</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 3 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/fill-dotted-/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Fill Dot</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 4 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/candy-blocks/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Candy Blocks</h6>
            </div>
          </div>
        </div>
        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 6 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/pattern-strings/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Pattern String</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 7 })}
              style={{ cursor: "pointer" }}
            >
              <div class="corner-ribbon">
                <span class="cr-inner">
                  <span class="cr-text">50🟡</span>
                </span>
              </div>
              <img
                src="https://quizzigo.com/games/paper-fold/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Paper Fold</h6>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default CardContainer;
