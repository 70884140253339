import React from 'react';
import Notenoughcoin from './notenough'; // Adjust the path accordingly

const notenoughcoins = ({ show, onHide }) => {
  return (
    <div>
      {show && <Notenoughcoin show={show} onHide={onHide} />}
      {/* Rest of notenoughcoins */}
    </div>
  );
};

export default notenoughcoins;
