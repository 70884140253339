import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link ,useLocation} from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import animatecoin from "../Assets/Projects/animation_coin.gif";
import rewardgif from "../Assets/Projects/rewardgif.gif";
import Earnedcoins from "./popups/earnedcoins";

import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { addToast } = useToasts();
  const location = useLocation();

    // Check if the current location is the home page
    const isHomePage = location.pathname.startsWith("/playerzone/");
    const redirect = location.pathname === "/"

    if(redirect){
      window.location.href ='/game/index.html'
    }
  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const dailyreward = () => {
    let oldReward = Number(localStorage.getItem('ads_reward'))

    if (oldReward < 5) {
      let adRewardFlag = true;
      let adWatch = false;
      
      window.adBreak({
        type: "reward",
        name: "dailyreward",
        beforeReward: (showAdFn) => {
          adRewardFlag = false;
          showAdFn();
        },
        adDismissed: () => {console.log("Ad Dismissed before time"); addToast('You exceeded the ad limit ', { appearance: 'warning', autoDismiss: true });},
        adViewed: () => {
          console.log("Ad Dismissed after time");
          adRewardFlag = true;
          adWatch = true;
        },
        adBreakDone: () => {
          if (adRewardFlag && adWatch) {
            let oldcoins = localStorage.getItem('coins')
            localStorage.setItem("coins", Number(oldcoins)+100);
            addToast('100 Coins Rewarded!', { appearance: 'success', autoDismiss: true });
          } else if (adRewardFlag) {
            addToast('RewardAds Not Available', { appearance: 'error', autoDismiss: true });
            console.log("RewardAds not available, Try Again");
          }
        },
      });
    } else {
      addToast('Daily Reward limit reached', { appearance: 'error', autoDismiss: true });
    }
  };

  const [coins, setCoins] = useState(0);
  const [timer, setTimer] = useState(0);


  useEffect(() => {
    if (isHomePage) {
      // Only update coins and timer when on home page
      // Function to update coins every minute
      const updateCoins = () => {
        setCoins((prevCoins) => prevCoins + 10);
        let oldcoins = localStorage.getItem("coins");
        localStorage.setItem("coins", Number(oldcoins) + 10);
      };

      // Function to update timer every second
      const updateTimer = () => {
        setTimer((prevTimer) => prevTimer + 1);
      };

      // Start the timer when component mounts
      const coinsTimer = setInterval(updateCoins, 60000); // 60000 milliseconds = 1 minute
      const secondsTimer = setInterval(updateTimer, 1000); // 1000 milliseconds = 1 second

      // Clean up the timers when component unmounts
      return () => {
        clearInterval(coinsTimer);
        clearInterval(secondsTimer);
      };
    }
  }, [isHomePage]); // Include isHomePage in the dependency array

  // const minutes = Math.floor(timer / 60);
  // const seconds = timer % 60;

  const [showModal, setShowModal] = useState(false);

  const openModalnocoin = () => {
      setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
       <Earnedcoins show={showModal} onHide={closeModal} />
      <Container>
      <div className="d-flex align-items-center">
          {isHomePage && (
            <div className="me-2 back-button">
              <Link to="/">
                <i
                  class="fa fa-home ms-2"
                  aria-hidden="true"
                  style={{ color: "white", fontSize: "25px" }}
                ></i>
              </Link>
            </div>
          )}
          <div
            className="wallet-btn px-2"
            style={{ cursor: "pointer" }}
            onClick={dailyreward}
          >
            <img src={rewardgif} alt="reward gif" height={25} />
          </div>

          <div className="wallet-btn px-2"  onClick={() => openModalnocoin()}
                style={{ cursor: "pointer" }}>
            {localStorage.getItem("coins")}
            <img src={animatecoin} alt="animationcoin" width={20} />
          </div>
          {isHomePage && (
            <div className="ms-1">
              <div className="box">
                <div className="water"></div>
              </div>
            </div>
          )}
        </div>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/home" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}>
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Privacy Policy
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
