import React, { useState, useEffect } from "react";
import { Container /* Row, Col */ } from "react-bootstrap";
import CardContainer from "../Grid/card_container";
import Mostplayed from "../Grid/most-played";
import Adventure from "../Grid/adventure";
// import RewardSpin from "../popups/spin";
import RewardAd from "../popups/app";
import InterAd1 from "../Ads/nativead";
import CoinsShow from "../../Assets/Projects/coins_show_animation.gif"
import Rewardgift from "../popups/rewardgift";

if (localStorage.length === 0) {
  localStorage.setItem("coins", 0);
  localStorage.setItem("ads_reward", 0);
}

if(!localStorage.getItem('coins')){
  localStorage.setItem("coins", 0);
}

if(!localStorage.getItem('ads_reward')){
  localStorage.setItem("ads_reward", 0);
}

function Home() {
  const [showModal, setShowModal] = useState(false);
  let rewardads = Number(localStorage.getItem("ads_reward"));

  useEffect(() => {
    const openModalrewardad = () => {
      if (rewardads < 2) {
        setShowModal(false);
      }
    };
    openModalrewardad(); // Call the function when the component mounts
  }, [rewardads]);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    // Check if the user has visited the page before
    const visitedBefore = localStorage.getItem('visitedBefore');

    // If not visited before
    if (!visitedBefore) {
      setShowElement(true); // Show the element
      localStorage.setItem('visitedBefore', true); // Update visitedBefore flag
    }
    // Cleanup function
    return () => {
      setShowElement(false); // Show the element
      // Uncomment this line if you want to reset the visitedBefore flag when component unmounts
      // localStorage.removeItem('visitedBefore');
    };
  }, []); // Run only once when component mounts

  useEffect(() => {
    if (showElement) {
      // Set a timeout to hide the element after 5 seconds
      const timeoutId = setTimeout(() => {
        setShowElement(false);
      }, 5000); // 5000 milliseconds = 5 seconds
      
      // Cleanup function to clear the timeout
      return () => clearTimeout(timeoutId);
    }
  }, [showElement]); // Run whenever showElement changes


  return (
    <section>
      <RewardAd show={showModal} onHide={closeModal} />
      <Container fluid className="" id="home">
  <Rewardgift show={showModal} onHide={closeModal} />

        <Container className="home-content">
          {/* <RewardSpin /> */}
          {/* <App/> */}
          {showElement && ( <img src={CoinsShow} alt="coinsshow" className="position-absolute img-fluid z-3" />)}

  
          <CardContainer />

          <div className="d-flex justify-content-center mb-2">
              <div style={{width:"100%", height:"300px"}}>
                    <ins className="adsbygoogle adslot_1"
                    style={{ display: "block"}}
                    data-ad-client="ca-pub-8026299207830445"
                    data-ad-slot="4327847264">
                </ins>
              </div>
            </div>
          <Mostplayed />
          <Adventure />
          <InterAd1/>
         
        </Container>
      </Container>
    </section>
  );
}

export default Home;
