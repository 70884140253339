import React, { useRef, useState } from "react";
import Notenoughcoin from "../popups/notenough-open";
import { useNavigate  } from 'react-router-dom';


function Sidebar(){
  // Start for not enought coin
  const [showModal, setShowModal] = useState(false);
  let coins = Number(localStorage.getItem("coins"));
  const navigate = useNavigate();


  const openModalnocoin = (geturl) => {
    // if (coins && coins < 50) {
      // InterAd Show
      window.adBreak({
        type: "start",
        name: "game_started",
        adBreakDone: (placementInfo) => {
          console.log(placementInfo.breakStatus);
          // let oldcoins = localStorage.getItem('coins')
            //  let deductcoins= Number(oldcoins)-50
            //  localStorage.setItem('coins', deductcoins)
          navigate("/gamedetails/"+ geturl.data)
        },
      })
  };

  const closeModal = () => {
    setShowModal(false);
  };
    return(
        <div className="sidebar">
<Notenoughcoin show={showModal} onHide={closeModal} />

        <div className="row p-3">
        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 25})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/save-the-dog/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 26})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/teeth-runner/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 ">
            <div onClick={() => openModalnocoin({data: 21})} style={{cursor:"pointer"}}>
             <img
                src="https://quizzigo.com/games/flip-bottle/thumb_2.jpg"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 22})} style={{cursor:"pointer"}}>
             <img
                src="https://quizzigo.com/games/fall-beans/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 28})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/jewel-classic/thumb_2.jpg"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 23})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/2k-shoot/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 24})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/match-shapes/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 27})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/Cricket/512.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 29})} style={{cursor:"pointer"}}>
              <img
                src="https://quizzigo.com/games/match-pair/thumb_2.jpg"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
           
          </div>
        </div>


        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 9 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/basketball-challenge/thumb_2.jpg"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 5 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/drawmaster/thumb_2.jpg"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 8 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/house-paint/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 1 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/slider/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 2 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/puzzle-color/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

        <div className="col-4 list-tile">
          <div className="game-card-1 position-relative">
            <div
              onClick={() => openModalnocoin({ data: 3 })}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://quizzigo.com/games/fill-dotted-/thumb_2.png"
                alt="gmaeimg"
                className="small-thumb"
              />
            </div>
          </div>
        </div>

          </div>
        </div>
    )
}

export default Sidebar;