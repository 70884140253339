import React ,{useEffect}from 'react';

const InterAd1 = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="d-flex justify-content-center mb-2">
              <div style={{width:"100%", height:"300px"}}>
              <ins class="adsbygoogle adslot_1"
                  style={{ display: "block"}}
                  data-ad-client="ca-pub-8026299207830445"
                  data-ad-slot="1540795224"></ins>
              </div>
    </div>
  );
};

export default InterAd1;