import React from "react";
import { Container } from "react-bootstrap";
// import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Privacy and Policy<strong className="purple"> </strong>
        </h1>

        <div className="row row-cols-1" style={{color:"#818181"}}> 
          <div className="col">
            <h1>Disclaimer</h1>
            <p>
              Liability All materials, information, software, products and
              services (also referred to as "Content"), included in or available
              through https://www.milodygames.com (also referred to as "Site")
              are provided on an "as is" basis. The Content is provided without
              warranties of any kind.By using the Content of the Site, you
              explicitly agree to these terms and conditions. We do not warrant
              that the Content on this Site is accurate, reliable or correct;
              that this Site will be available at any particular time or
              location; that any defects or errors will be corrected; or that
              the Content is free of viruses or other harmful components. Your
              use of this Site is solely at your own risk. Any Content viewed,
              downloaded or otherwise obtained through the use of this Site is
              done solely at your own discretion and risk, and you will be
              solely responsible for any damage, including without limitation
              personal injury or distress, damage to your computer system, or
              loss of data, that results from the viewing or download of any
              Content. The owner can not be held responsible for any problems
              occuring by using or not using the Site and its Content in any
              way. Links, advertisements and third party sites This Site
              provides advertisements and links to other third party websites
              over which we have no control. We can not be held responsible for
              those advertisements and websites or any of its Content. You
              acknowledge that you view and access these advertisements and
              websites entirely at your own risk.
            </p>
          </div>

          <div className="col">
            <h1>Terms Of Use / Copyright / Licensing</h1>
            <p>
              Content of the owner of Milody Games The site Milody Games may not
              be copied, reproduced, republished, uploaded, posted, transmitted,
              or distributed, in whole or partly, for any purpose other than
              individual viewing of this content, without the express prior
              written consent of the owner. Any modification, decompilation or
              reverse-engineering of the owners copyrighted content for any
              purpose is prohibited.Content of Third Parties Games and other
              content on the site which is not made and copyrighted by the owner
              of this site, is either licensed or used with permission and
              protected by international copyright. These games are intellectual
              property and/or copyright and/or trademark by their respective
              owners and/or authors.If you are the developer/owner/copyright
              holder of a game and you wish to have it removed by the owner of
              this site, please email us by contact form.
            </p>
          </div>

          <div className="col">
            <h1>Privacy Policy</h1>
            <p>
              Automatically logged information (server logs), cookies, Flash
              shared objects
            </p>
          </div>

          <div className="col">
            <h1>Server logs:</h1>
            <p>
              Like most servers, our servers automatically record the page
              requests made when users visit our Site. These "server logs"
              typically include your web request (the requested content), IP
              address, browser type, browser language and the date and time of
              your request. We use this information to help diagnose problems,
              to analyze statistics of our Site, and to allow us to improve the
              overall user-experience. No personal information (like your name,
              email or phone number) is being gathered with server logs. We do
              not transfer these data to third parties as long as it is not
              required by law.
            </p>
          </div>

          <div className="col">
            <h1>Cookies:</h1>
            <p>
              The Site also uses the third party web analytics service "Google
              Analytics" (GA), by Google, Inc. GA use "cookies", which are text
              files placed on your computer, to help the website analyze how
              users use the site. The information generated by the cookie about
              your use of the website (including your IP address) will be
              transmitted to and stored by Google on servers in the United
              States. Google will use this information for the purpose of
              evaluating your use of the website, compiling reports on website
              activity for website operators and providing other services
              relating to website activity and internet usage. Google may also
              transfer this information to third parties where required to do so
              by law, or where such third parties process the information on
              Google's behalf. Google will not associate your IP address with
              any other data held by Google. You may refuse the use of cookies
              by selecting the appropriate settings on your browser, however
              please note that if you do this you may not be able to use the
              full functionality of this website. No personal information (like
              your name, email or phone number) is being gathered with cookies.
            </p>
            <p>
              By using this Site, you consent to the processing of data about
              you by Google in the manner and for the purposes set out above.
            </p>
          </div>

          <div className="col">
            <h1>Third Party Advertising</h1>
            <p>
              We use several third party advertising companies to serve ads when
              you visit the Site. These companies may use cookies to store
              information about your visits to this and other websites (your
              browsing activity) in order to provide advertisements on this Site
              and other sites about goods and services that may be of interest
              to you. You may refuse the use of cookies by selecting the
              appropriate settings on your browser.
            </p>
          </div>

          <div className="col">
            <h1>Google Adsense:</h1>
            <p>
              Delivered by Google, Inc., may make use of one or more
              "DoubleClick DART Cookies".
            </p>
            <p>
              DART cookies store and collect data on your computer about your
              browsing activity. Google may use this data to show advertisements
              on this Site based on your browsing activity.
            </p>

            <p>
              You can choose to opt out of Google's use of cookies by visiting:
            </p>
            <p>the <a href="https://www.google.com/privacy_ads.html"> Google ad and content privacy policy.</a></p>
            <p>Contact via email: <a href="mailto:info@milodygames.com"> info@milodygames.com</a></p>
            <p>
              When you contact us via email, by using a contact form, you are
              providing us with personal information such as your name and email
              address. Such information is used only for the purpose of
              assisting you with, and validating your inquiry, request or
              submission. We respect the privacy concerns of visitors to
              https://www.milodygames.com .
            </p>
            <p>
              We do not sell or disclose your email address or any other
              personal information about you.
            </p>
            <p>
              If you have any questions about this Disclaimer, You can contact
              Us:
            </p>
            <p>By email: <a href="mailto:info@milodygames.com">info@milodygames.com</a></p>
            <p>
              Links We may present internal links (i.e. links to our own pages)
              or external links (i.e. links to other websites) in a format that
              allows us to track statistics like the number of clicks a link
              gets. These statistics will only be used to help us improve the
              site. We are not responsible for the privacy practices or the
              content of websites that are being linked to from our Site or
              newsletters.Security We protect personal information against such
              risks as loss or theft, unauthorized access, disclosure, copying,
              use, modification or destruction, through appropriate security
              measures.
            </p>
          </div>
        </div>
      </Container>
    </Container>
  );
}

export default Projects;
