import React, { useRef, useState } from "react";
import Notenoughcoin from "../popups/notenough-open";
import { useNavigate  } from 'react-router-dom';

function Adventure() {
  const scrollListRef = useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  setTimeout(scrollRight, 3000)
  setTimeout(scrollLeft, 5000)

  // Start for not enought coin
  const [showModal, setShowModal] = useState(false);
  let coins = Number(localStorage.getItem("coins"));
  
  const openModalnocoin = (geturl) => {
    // if (coins && coins < 50) {
      // InterAd Show
      window.adBreak({
        type: "start",
        name: "game_started",
        adBreakDone: (placementInfo) => {
          console.log(placementInfo.breakStatus);
          // let oldcoins = localStorage.getItem('coins')
            //  let deductcoins= Number(oldcoins)-50
            //  localStorage.setItem('coins', deductcoins)
          navigate("/gamedetails/"+ geturl.data)
        },
      })
  };

  const closeModal = () => {
    setShowModal(false);
  };
// End of not enough coin
  
return (

<div className="card-container  mb-3">
<Notenoughcoin show={showModal} onHide={closeModal} />
      <div className="row py-3 px-1">
        <div className="col">
          <h4>
            Adventure <i className="fa-solid fa-rocket"></i>
          </h4>
        </div>
      
        <div className="col d-flex justify-content-end">
          <div>
            <button className="scroll-btn" onClick={scrollLeft}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </div>
          <div>
            <button className="scroll-btn" onClick={scrollRight}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-flex overflow-x-scroll text-center"
        ref={scrollListRef}
        id="populargame"
      >
         <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 25})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/save-the-dog/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Save Dog</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 26})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/teeth-runner/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Theeth Cleaner</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 ">
            <div onClick={() => openModalnocoin({data: 21})} style={{cursor:"pointer"}}>
            <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/flip-bottle/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Flip Bottle</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 22})} style={{cursor:"pointer"}}>
            <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/fall-beans/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Fall Beans</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 28})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/jewel-classic/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Jewel Classic</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 23})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/2k-shoot/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>2k Shoot</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 24})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/match-shapes/thumb_2.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Match Shapes</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 27})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/Cricket/512.png"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Cricket</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 29})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>

              <img
                src="https://quizzigo.com/games/match-pair/thumb_2.jpg"
                alt="gmaeimg"
              />
            </div>
            <div className="p-2">
              <h6>Match Pair</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adventure;
