import React from "react";
import image404 from "../Assets/Projects/404.png";

function universelpage() {
  return (
    <div className="mt-5">
      <div className="mt-5 pb-5 d-flex justify-content-center">
        <img src={image404} alt="404img" className="img-fluid" />
      </div>
    </div>
  );
}

export default universelpage;
