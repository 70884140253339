import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Rewarded() {
    const [smShow, setSmShow] = useState(false);

    useEffect(() => {
      if (smShow) {
        // Set a timeout to close the modal after 2000 milliseconds (2 seconds)
        const timeoutId = setTimeout(() => {
          setSmShow(false);
        }, 2000);
  
        // Clear the timeout if the component unmounts or the modal is closed manually
        return () => clearTimeout(timeoutId);
      }
    }, [smShow]);

  return (
    <>
      <Button onClick={() => setSmShow(true)} className="me-2">
       Claim
      </Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body closeButton>
            <p className='game-text'>100 Coins Added</p>
            <div className="loading-line"></div>
        </Modal.Body>
      </Modal>
   
    </>
  );
}

export default Rewarded;