import React, { useRef, useState } from "react";
import Notenoughcoin from "../popups/notenough-open";
import { useNavigate } from 'react-router-dom';

function Mostplayed() {
  const scrollListRef = useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollListRef.current) {
      scrollListRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  setTimeout(scrollRight, 3000)
  setTimeout(scrollLeft, 5000)

    // Start for not enought coin
    const [showModal, setShowModal] = useState(false);
    let coins = Number(localStorage.getItem("coins"));
    
    const openModalnocoin = (geturl) => {
      // if (coins && coins < 50) {
          // InterAd Show
          window.adBreak({
            type: "start",
            name: "game_started",
            adBreakDone: (placementInfo) => {
              console.log(placementInfo.breakStatus);
              // let oldcoins = localStorage.getItem('coins')
            //  let deductcoins= Number(oldcoins)-50
            //  localStorage.setItem('coins', deductcoins)
              navigate("/gamedetails/"+ geturl.data)
            },
          })
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
  // End of not enough coin

  return (
    <div className="card-container  mb-3">
      <Notenoughcoin show={showModal} onHide={closeModal} />
      <div className="row py-3 px-1">
        <div className="col">
          <h4>
           Most Played <i className="fa-solid fa-gamepad"></i>
          </h4>
        </div>
        <div className="col d-flex justify-content-end">
          <div>
            <button className="scroll-btn" onClick={scrollLeft}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </div>
          <div>
            <button className="scroll-btn" onClick={scrollRight}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-flex overflow-x-scroll text-center"
        ref={scrollListRef}
        id="populargame"
      >
         <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 13})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/toilet-race/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Toilet Rush</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 12})} style={{cursor:"pointer"}}>
            <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/troll-thief/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Troll Thief</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 20})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/fruit-master/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Fuit Master</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 18})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://milodygames.com/games/free-bird/thumb_2.png"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Free Bird</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 14})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/roll-the-ball/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Roll Ball</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 15})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/onet-3d/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Onet 3D</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 16})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/grab-pack/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Grab Pack</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 17})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/poly-art/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Poly Art</h6>
            </div>
          </div>
        </div>
      
        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 19})} style={{cursor:"pointer"}}>
              <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡 
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/monster-go/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Monster Go</h6>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="game-card-1 position-relative">
            <div onClick={() => openModalnocoin({data: 11})} style={{cursor:"pointer"}}>
            <div class="corner-ribbon">
              <span class="cr-inner">
                <span class="cr-text">
                  50🟡
                </span>
              </span>
            </div>
              <img
                src="https://quizzigo.com/games/pixel-slilde/thumb_2.jpg"
                alt="gmaeimg"
              />
              </div>
            <div className="p-2">
              <h6>Pixel Slide</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mostplayed;
